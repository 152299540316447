<template>
	<div class="home">
		<Header />
		<About />
		<Services />
		<Targets />
		<ContactLink />
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

const Header = defineAsyncComponent(() => import('../components/Home/Header'));
const About = defineAsyncComponent(() => import('../components/Home/About'));
const Targets = defineAsyncComponent(() => import('../components/Home/Targets'));
const Services = defineAsyncComponent(() => import('../components/Home/Services'));
const ContactLink = defineAsyncComponent(() => import('../components/ContactLink'));
export default {
	name: 'Home',
	components: { Header, About, Services, ContactLink, Targets },
};
</script>
